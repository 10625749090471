.pricingheading{
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}
.pricingsubheading{
    font-family: 'Poppins';
    font-size: 17px;
    text-align: justify;
    margin-top: 40px;
    font-weight: 600;
    color: #0D0D0D;
}
.pricingheading h2{
    line-height: 49px;
    text-align: center;
    font-weight: 700;
}
.form-floating>label::after{
    background-color: transparent !important;
    color: #ffff;
}
.form-floating>label{
    color: #a9a9a9 !important;
}

.signupf{
    display: flex;
    align-items: center;
    justify-content: center;
}
.signupf Button:hover{
    box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
}
.signupf Button{
    border-radius: 30px;
    margin: 34px 0px 0px 0px;
    padding: 12px 40px;
    color: #000000de;
    font-weight: 700;
}
.toggoleSection{
        width: 56%;
}
.pricingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
input#custom-switch {
    padding: 10px 20px;
    width: 54px;
    height: 26px;
    /* background-color: beige; */
}

label.form-check-label{
    display: inline-block;
    margin: 4px 18px;
}
.form-check-input{
    background-color: #009A59;
}
/* ----------- */

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 10px;
    bottom: 8px;
    background-color: white;
    transition: .4s;
}
  
  input:checked + .slider {
    background-color: #21f39c;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .sidecontents{
    margin-left: 13px;
  }
  /* ---------------- */

.toggleList li{
    list-style: none;
    display: inline-block;
    padding-left: 10px;
    font-size: 17px;
}
.sicon{
    padding: 0px 10px;
}

.stoggleList li{
    list-style: none;
    display: inline-block;
    font-size: 17px;
    margin-left: 33px;
}

.headerSection h5{
    background-color: #F1F1F1;
    border-radius: 7px;
    display: inline-block;
    padding: 5px 10px;
    color: #191A1B;
    font-size: 14px;
    font-weight: 600;
    
}
.pricingList{
    width: 80%;
    
}
/* Start First Section */
.firstContainer{
    background-color: #FFFFFF;
    padding: 25px 35px 25px 25px;
    border-radius: 10px;
    transition: all .3s ease-out;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}
.firstContainer:hover{
    background-color: #FFFFFF;
    padding: 25px 35px 25px 25px;
    border-radius: 10px;
    transition: all .3s ease-out;
    transform: scale(1.1);
}

.subheaderSection{
    color: #414141;
    font-family: 'Poppins', sans-serif;
    width: max-content;
}


.subheaderSection p{
    font-size: 12px;
    font-weight: 600;
    word-spacing: 0px;
}
.priceIn{
    color: #191A1B;
    font-family: 'Prata', serif;
    font-size: 50px;
}
.pricetext{
    color: #000000;
    font-weight: 700;
    font-size: 13px;
}
.servicesinprice ul{
    padding: 0;
}
.servicesinprice ul li{
    color: #414141;
    list-style: none;
    margin: 7px 0px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.servicesinprice ul li span{
    color: #141414;
    margin-right: 5px;
}
.buttons{
    width: 100%;
}
.buttons Button{
    width: 85%;
    border-radius: 28px;
    padding: 3px;
    margin-right: 32px;
    margin-top: 13px;
    background-color: #000000;
}

/* end First Section */

/* Start Second Section */
.secondContainer{
    background-color: #000000;
    padding: 25px 35px 25px 25px;
    border-radius: 10px;
}

.secondContainer .subheaderSection{
    color: #F1F1F1;
    font-family: 'Poppins', sans-serif;
    width: max-content;
}

.secondContainer .subheaderSection p{
    font-size: 11px;
    font-weight: 500;
    word-spacing: 0px;
}
.secondContainer .priceIn{
    color: #FFFFFF;
    font-family: 'Prata', serif;
    font-size: 50px;
}
.secondContainer .pricetext{
    color: #FFFFFF;
    font-weight: 700;
    font-size: 13px;
}
.secondContainer .servicesinprice ul{
    padding: 0;
}
.secondContainer .servicesinprice ul li{
    color: #FFFFFF;
    list-style: none;
    margin: 7px 0px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.secondContainer .servicesinprice ul li span{
    color: #FFFFFF;
    padding-right: 5px;
}
.secondContainer .buttons{
    width: 100%;
}
.secondContainer .buttons Button{
    width: 85%;
    border-radius: 28px;
    padding: 3px;
    margin-right: 32px;
    margin-top: 13px;
    background-color: #009A59;
    color: #000000;
    font-weight: 700;
}

/* Start responsive section */
@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .toggleList li {
        list-style: none;
        display: inline-block;
        padding-left: 10px;
        font-size: 15px;
    }
    .thirdItem {
        position: absolute;
        z-index: 9;
        bottom: 131px;
        left: 132px;
        padding: 14px 36px;
        border-radius: 5px 0px 5px 5px;
        box-shadow: 2px 7px 5px 0px rgb(0 0 0 / 75%);
        -webkit-box-shadow: 2px 7px 5px 0px rgb(0 0 0 / 75%);
        -moz-box-shadow: 2px 7px 5px 0px rgba(0,0,0,0.75);
    }
    .firstItem {
        position: absolute;
        z-index: 9;
        top: -28px;
        left: 134px;
       
        padding: 14px 36px;
        border-radius: 5px 5px 0px 5px;
        box-shadow: 2px 7px 5px 0px rgb(0 0 0 / 75%);
        -webkit-box-shadow: 2px 7px 5px 0px rgb(0 0 0 / 75%);
        -moz-box-shadow: 2px 7px 5px 0px rgba(0,0,0,0.75);
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
   
    .toggleList {
        margin-left: 36px;
    } 
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .toggleList li {
        list-style: none;
        display: inline-block;
        padding-left: 10px;
        font-size: 12px;
    }
     
 }

 @media screen and (min-width: 575px) and (max-width: 767px) {
    .pricingheading h2 {
        line-height: 38px;
        text-align: center;
        
    }
    .toggleList li {
        list-style: none;
        display: inline-block;
        padding-left: 10px;
        font-size: 15px;
    }
    .pricingsubheading {
        font-family: 'Poppins', sans-serif;
        font-size: 17px;
        text-align: center;
        margin-top: 30px;
    }
    .pricingsubheading p br{
        display: none;
    }
 }
 @media screen and (min-width: 300px) and (max-width: 574px) {
    .pricingheading h2 {
        line-height: 35px;
        text-align: center;
        font-size: 24px;
    }
    .pricingheading h2 br{
        display: none;
    }
    .pricingsubheading {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        text-align: center;
        margin-top: 17px;
    }
    .toggleList li {
        list-style: none;
        display: inline-block;
        padding-left: 10px;
        font-size: 14px;
        width: 100%;
    }
    .toggoleSection {
        width: 100%;
    }
    .signupf Button {
        border-radius: 30px;
        margin: 34px 0px 0px 0px;
        padding: 11px 18px;
        color: #000000de;
        font-weight: 700;
        font-size: 13px;
    }
    .socialIcon ul {
        display: flex;
        justify-content: center;
    }
    .toggleList {
        text-align: center;
    }
     
 }


/* End REsponsive Section */

/* @media screen and (min-width: 768px ) and (max-width: 991px) {
    
    .subheaderSection{
        width: 100%;
    }
    .subheaderSection p {
        font-size: 10px;
        font-weight: 600;
        word-spacing: 0px;
    }
    .subheaderSection p br{
       display: none;
    }
    .secondContainer .subheaderSection p {
        font-size: 10px;
        font-weight: 500;
        word-spacing: 0px;
    }
    .secondContainer .subheaderSection {
        color: #F1F1F1;
        font-family: 'Poppins', sans-serif;
        width: 100%;
    }
    .servicesinprice ul li {
        color: #414141;
        list-style: none;
        margin: 7px 0px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 10px;
    }
    .secondContainer .servicesinprice ul li {
        color: #FFFFFF;
        list-style: none;
        margin: 7px 0px;
        font-size: 9px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }
    .buttons Button {
        width: 85%;
        border-radius: 28px;
        padding: 3px;
        margin-right: 32px;
        margin-top: 13px;
        background-color: #000000;
        font-size: 12px;
    }
    .heading h2 {
        font-weight: 200;
        font-family: 'Prata', serif;
        margin-top: 26px;
        font-size: 20px;
    }
   
 
   }
   @media screen and (min-width: 992px ) and (max-width: 1199px) {
    
    .buttons Button {
        width: 85%;
        border-radius: 28px;
        padding: 3px;
        margin-right: 32px;
        margin-top: 13px;
        background-color: #000000;
        font-size: 13px;
    }
 
   }
   @media  (min-width: 300px) and (max-width: 767px){
    .toggleList li {
        list-style: none;
        display: inline-block;
        padding-left: 10px;
        font-size: 15px;
    }
    .signupf Button {
        border-radius: 30px;
        margin: 34px 0px 0px 0px;
        padding: 12px 5px;
        color: #000000de;
        font-weight: 700;
    }
    .firstContainer {
        background-color: #FFFFFF;
        padding: 25px 35px 25px 25px;
        border-radius: 10px;
        margin-bottom: 14px;
    }
    .secondContainer {
        background-color: #000000;
        padding: 25px 35px 25px 25px;
        border-radius: 10px;
        margin-bottom: 14px;
    }
    .toggleList li {
        list-style: none;
        display: inline-block;
        padding-left: 10px;
        font-size: 15px;
    }
    .buttons Button {
        width: 85%;
        border-radius: 28px;
        padding: 3px;
        margin-right: 32px;
        margin-top: 13px;
        background-color: #000000;
        font-size: 13px;
    }
    .subheaderSection {
        color: #414141;
        font-family: 'Poppins', sans-serif;
        width: max-content;
        width: 100%;
    }
    .subheaderSection {
        color: #414141;
        font-family: 'Poppins', sans-serif;
        width: 100%;
    }
    .subheaderSection p br{
        display: none;
    }
    .toggoleSection{
        width: 100%;
    }
    .servicesinprice ul li {
        color: #414141;
        list-style: none;
        margin: 7px 0px;
        font-size: 10px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }
    .secondContainer .servicesinprice ul li {
        color: #FFFFFF;
        list-style: none;
        margin: 7px 0px;
        font-size: 10px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }
    .secondContainer .subheaderSection{
        width: 100%;
    }
    
}

@media screen and (max-width: 300px){
    .firstContainer {
        background-color: #FFFFFF;
        padding: 25px 35px 25px 25px;
        border-radius: 10px;
        margin-bottom: 14px;
    }
    .secondContainer {
        background-color: #000000;
        padding: 25px 35px 25px 25px;
        border-radius: 10px;
        margin-bottom: 14px;
    }
    .toggleList li {
        list-style: none;
        display: inline-block;
        padding-left: 10px;
        font-size: 14px;
    }
    .buttons Button {
        width: 85%;
        border-radius: 28px;
        padding: 3px;
        margin-right: 32px;
        margin-top: 13px;
        background-color: #000000;
        font-size: 13px;
    }
    .subheaderSection {
        color: #414141;
        font-family: 'Poppins', sans-serif;
        width: max-content;
        width: 100%;
    }
    .subheaderSection {
        color: #414141;
        font-family: 'Poppins', sans-serif;
        width: 100%;
    }
    .subheaderSection p br{
        display: none;
    }
    .toggoleSection{
        width: 100%;
    }
    .servicesinprice ul li {
        color: #414141;
        list-style: none;
        margin: 7px 0px;
        font-size: 10px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }
    .secondContainer .servicesinprice ul li {
        color: #FFFFFF;
        list-style: none;
        margin: 7px 0px;
        font-size: 10px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }
    .secondContainer .subheaderSection{
        width: 100%;
    }
} */
   


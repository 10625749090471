.modal-header .btn-close{
    filter: invert(1);
}
.border_red{
    border: 2px solid rgba(206, 38, 38, 0.473) !important;
}

/* loader */




@keyframes ldio-fg7oryce9mw-1 {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(-45deg) }
  100% { transform: rotate(0deg) }
}
@keyframes ldio-fg7oryce9mw-2 {
    0% { transform: rotate(180deg) }
   50% { transform: rotate(225deg) }
  100% { transform: rotate(180deg) }
}
.ldio-fg7oryce9mw > div:nth-child(2) {
  transform: translate(-15px,0);
}
.ldio-fg7oryce9mw > div:nth-child(2) div {
  position: absolute;
  top: 0px;
  left: 20px;
  width: 60px;
  height: 30px;
  border-radius: 60px 60px 0 0;
  background: #ffffff;
  animation: ldio-fg7oryce9mw-1 0.5681818181818182s linear infinite;
  transform-origin: 30px 30px
}
.ldio-fg7oryce9mw > div:nth-child(2) div:nth-child(2) {
  animation: ldio-fg7oryce9mw-2 0.5681818181818182s linear infinite
}
.ldio-fg7oryce9mw > div:nth-child(2) div:nth-child(3) {
  transform: rotate(-90deg);
  animation: none;
}@keyframes ldio-fg7oryce9mw-3 {
    0% { transform: translate(95px,0); opacity: 0 }
   20% { opacity: 1 }
  100% { transform: translate(35px,0); opacity: 1 }
}
.ldio-fg7oryce9mw > div:nth-child(1) {
  display: block;
}
.ldio-fg7oryce9mw > div:nth-child(1) div {
  position: absolute;
  top: 28px;
  left: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e15b64;
  animation: ldio-fg7oryce9mw-3 0.5681818181818182s linear infinite
}
.ldio-fg7oryce9mw > div:nth-child(1) div:nth-child(1) { animation-delay: -1.1792s }
.ldio-fg7oryce9mw > div:nth-child(1) div:nth-child(2) { animation-delay: -0.5808s }
.ldio-fg7oryce9mw > div:nth-child(1) div:nth-child(3) { animation-delay: 0s }
.loadingio-spinner-bean-eater-6yekvqukeod {
  width: 30px;
  height: 30px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
  margin-left: 10px;
}
.ldio-fg7oryce9mw {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.48);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-fg7oryce9mw div { box-sizing: content-box; }
/* generated by https://loading.io/ */

input.PhoneInputInput {
  border: none;
  background: transparent;
  color: #ffff;
}
input.PhoneInputInput:focus{
  border: none;
  background: transparent;
  color: #ffff;
  outline: none;
}

.phoneNumberT{
  padding: 12px;
  border-radius: 8px;
  background: transparent;
  border: 1px solid #ffff;
  color: #ffff;
  margin-bottom: 20px;
}
.modal-header{
  display: block !important;
}
.modal_description {
  color: #e3e3e3;
  margin-top: 5px;
  font-size: 12px;
  margin-bottom: 0px;
  font-family: "Poppins";
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
  position: absolute;
  top: 20px;
  right: 40px;
}
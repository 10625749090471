.clientName h5{
    font-weight: 300;
}
.coteicon{
    margin: 30px 0px;
}

.testimonial .slick-arrow.slick-prev,
.testimonial .slick-arrow.slick-next {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 5px;
}
.testimonial .slick-prev::before,
.testimonial .slick-next::before {
  display: none;
}
@media  (min-width: 300px) and (max-width: 767px){
  .slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.bodyoft{
  text-align: center;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  line-height: 22px !important;
  font-size: 15px !important;
  color: #ffff !important;
  margin-top: 33px;
}
.arrowContainer{
  margin-left: 13px !important;
  margin-top: 33px !important;
}
}
  


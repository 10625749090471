.upload_csv {
  margin-left: 23px;
}

.logoLink {
  max-width: 173px;
}
.dataAnatics {
  color: #191a1b;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
}
.upload_csv {
  color: #191a1b;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
}
.upload_csv:hover {
  color: #198754;
}
.dataAnatics:hover {
  color: #198754;
}
.dash_button a {
  border-radius: 20px;
  margin: 20px 0px 20px 21px;
  padding: 6px 25px;
}
.bell_icon {
  background-color: #191A1B;
  height: 36px;
  width: 36px;
  line-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 20px 0px 20px 13px;
  cursor: pointer;
}
.buttons_group {
  float: right;
}
.sidebarCross {
  margin: 30px 0px 0px 30px;
  color: #ffffff;
}
.sidebar {
  width: 100%;
  height: 100vh;
  background-color: #1b1919fb;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  position: fixed;
}
.navbar_main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
}
.menuCon .menuItem {
  list-style: none;
  color: rgb(66, 63, 63);
  border-bottom: 1px solid #ffffff52;
  width: 100%;
  padding: 10px;
  font-weight: 600;
}
.menuCon .menuItem {
  color: #ffffff;
}
.menuCon .menuItem:hover {
  background-color: #858282d5;
  color: #ffffff;
}
.sign-up-now{
  font-family: Montserrat, sans-serif !important;
}
.menuCon {
  width: 100%;
  padding: 0px;
}
.dashboardbtn:hover {
  box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.75);
}
.bell_icon:hover {
  -webkit-box-shadow: -1px 3px 34px -15px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 3px 34px -15px rgba(0,0,0,0.75);
  box-shadow: -1px 3px 34px -15px rgba(0,0,0,0.75);
}
.bell_icon:hover .bellimg {
  animation: headShake;
  animation-duration: 2s;
}

.offer_bar {
  background: #008143;
  text-align: center;
  color: #fff;
  padding: 8px 15px;
}
.offer_bar.sign_up{
  /* background-image: linear-gradient(216deg, rgba(32, 167, 110, 0.4901960784) 0%, #007040 100%) !important; */
  /* background: unset; */
  background-color: rgba(13, 110, 253, 0.13);
  border-color: rgba(13, 110, 253, 0.13);
  border-width: 1px;
  border-style: solid;
  padding: 12px 20px;
  border-radius: 8px;
  color: #0D6EFD;
}

.offer_bar h5 {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 1.6;
  font-weight: 400;
}

.offer_bar h5 span {
  background: #191a1b;
  padding: 3px 6px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 600;
}
.offer_bar.sign_up h5 span {
  background-color: #0D6EFD;
  color: #fff;
}

/* start media query */
@media screen and (min-width: 300px) and (max-width: 991px) {
  .link_button li {
    font-size: 11px;
  }
  .link_button {
    padding: 0px;
  }
}

@mixin xsm {
    @media only screen and (max-width: 425px) {
        @content;
    }
}

@mixin sm {
    @media only screen and (max-width: 575px) {
        @content;
    }
}

@mixin md {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin lg {
    @media only screen and (max-width: 991px) {
        @content;
    }
}

@mixin xl {
    @media only screen and (max-width: 1199px) {
        @content;
    }
}

@mixin xxl {
    @media only screen and (max-width: 1399px) {
        @content;
    }
}

@mixin cl {
    @media only screen and (min-height: 800px) {
        @content;
    }
}

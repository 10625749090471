.accordion-header button, .accordion-button[aria-expanded="true"] {
    background: #e0ecff;
    color: #333;
    /* box-shadow: 0 0 15px 0 #000!important; */
    box-shadow: none!important;
}
.accordion-button::after{
    display: none;
}
.accordion-button{
    position: relative;
    z-index: 0;
    padding-right: 25px;
    border-radius: .25rem!important;
}
.acc_icon{
    position: absolute;
    top: 15px;
    right: 20px;    
}
.accordion-button[aria-expanded="true"] .acc_icon {
    transform: rotate(180deg);
}
.accordion-item {
    border-color: #e0ecff;
    margin-bottom: 1rem;
    border-top: 1px solid #e0ecff!important;
    border-radius: .25rem!important;
}
.accordion-button {
    border-radius: 0px!important;
}
.content_area{
    min-height: calc(100vh - 370px);
}
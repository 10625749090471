.fheading {
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}
.icon {
  padding-bottom: 20px;
}

.fsubheading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #818181;
}
.ourfheading {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.fheading h3 {
  font-size: 40px;
  font-weight: 700;
}
.ourfeaturess {
  padding-left: 3rem;
  margin-left: 1.5rem;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .ourfheading {
    font-size: 17px;
  }
  .fsubheading br {
    display: none;
  }
  .fheading h3 {
    font-size: 32px;
  }
  .fsubheading {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  }
}
@media screen and (min-width: 300px) and (max-width: 767px) {
  .ourfeaturess {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .fheading h3 {
    font-size: 32px;
  }
}
/* @media screen and (min-width: 300px ) and (max-width: 767px) {
   .icon{
    display: flex;
    align-items: center;
    justify-content: center;
   }
   .ourfeaturess {
    padding-left: 0px;
    margin-left: 0;
   }
   .ourfeaturess h5{
    text-align: center;

   }
   .fsubheading{
    text-align: center;
   }
   .fheading h3 {
    font-size: 28px;
}
.fsubheading {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

  }

  @media screen and (min-width: 768px ) and (max-width: 991px) {
    .ourfeaturess h5{
        font-size: 13px;
    }
    .ourfeaturess p{
        font-size: 11px;
    }
 
   } */

.socialIcon ul li {
    list-style: none;
    margin: 5px;
    font-size: 26px;
    display: flex;
    padding: 6px;
}
.QuickLinksItems li a {
    text-decoration: none;
    color: #818181;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.logo img{
    width: 150px;
}
.cdesign{
    background-color: #414141;
    color: #fff !important;
}
.LegalStuffItems ul li a{
    text-decoration: none;
    color: #818181;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.socialIcon ul li:hover{
    background-color: #009A59;
    border-radius: 5px;
    cursor: pointer;
}
.qheading h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 23px;
    font-weight: 600;
}

.socialIcon {
    margin-top: 110px;
}
.socialIcon ul{
    display: flex;
    padding:  0px;
}

.QuickLinksItems ul li {
    list-style: none;
    padding: 5px 0px;
}
.QuickLinksItems ul li a:hover {
  color: #009A59;
  cursor: pointer;
}

.LegalStuffHeading h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 23px;
    font-weight: 600;
}

.QuickLinksItems ul {
    padding: 0;
    margin-top: 20px;
}

.LegalStuffItems ul {
    padding: 0;
    margin-top: 20px;
}

.LegalStuffItems ul li {
    list-style: none;
    padding: 5px 0px;
}
.LegalStuffItems ul li a:hover{
    color: #009A59;
}
.nav-link{
    display: inline-block !important;
}

.cdesign {
    position: relative;
    padding: 12px 117px 12px 12px !important;
    width: 100%;
    outline: none !important;
    border-radius: 30px !important;
    border: none;

}

.dButton {
    position: absolute !important;
    top: 5px;
    right: 5px;
    bottom: 5px;
    width: 110px;
    border-radius: 30px !important;
    color: #FFFF !important;
    border: none !important;
    font-family: 'Poppins', sans-serif;
}
.dButton:hover{
    background-color: #133426 !important;
    border: none !important;
}
.success{
    background-color: #009A59;
}
.ftexty{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}
@media screen and (min-width: 768px) and (max-width: 1199px) {

    .socialIcon ul li {
        list-style: none;
        display: inline-block;
        margin: 5px;
        font-size: 16px;
    }
    .socialIcon {
        margin-top: 100px;
    }

    .QuickLinks {
        padding: 0px 0px 0px 21px;
    }

    .qheading h3 {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }

    .LegalStuffHeading h3 {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }

    .dButton {
        position: absolute !important;
        top: 5px;
        right: 5px;
        bottom: 5px;
        width: 66px;
        border-radius: 30px !important;
        color: #FFFF !important;
        font-size: 11px !important;
        overflow: hidden;
    }

    .cdesign {
        position: relative;
        padding: 12px 0px 12px 14px !important;
        width: 100%;
        outline: none !important;
        border-radius: 30px !important;
        border: none;
        font-size: 11px;
    }

    .newslaterHeading h3{
        font-family: 'Montserrat', sans-serif;
        font-family: 600;
font-style: normal;
font-weight: 100;
font-size: 22px;
line-height: 34px;
color: #191A1B;
    }


    
}
@media  (min-width: 300px) and (max-width: 767px){
    .qheading h3 {
        font-family: 'Prata', serif;
        font-size: 17px;
    }
    .logo{
        display: flex;
    align-items: center;
    justify-content: center;
    }
    .socialIcon{
        margin-top: 30px;
        display: flex;
    align-items: center;
    justify-content: center;
    }
    .QuickLinks{
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 20px;
    }
    .LegalStuff h3{
        text-align: center;
        font-size: 17px;
        margin-top: 20px;
    }
    .LegalStuff{
        text-align: center;
    }
    .newslaterHeading{
        text-align: center;
    }
    
}
@import './mixin.scss';

.sidebar {
    background-color: #fff9;
    backdrop-filter: blur(10px);
    transition: all ease 0.3s;
    left: unset;
    right: -100%;
    &.active {
        right: 0;
    }
}

.logo.d-flex {
    cursor: pointer;
}


.menuCon .menuItem {
    color: #333;
    border-color: #333;
}

.sidebarCross {
    color: #333;
}

.sidebarlogo img {
    margin: 0!important;
    padding: 10px 0;
    max-width: 200px;
}

.sidebarCross {
    color: #333;
    margin: 0;
    margin-left: auto;
}
.sidebarlogo {
    display: flex;
    align-items: center;
    padding: 0px 20px;
}

.bell_icon {
    width: 32px;
    height: 32px;
}

.nformed-btn {
    background-color: #0D6EFD;
    padding: 9px, 15px, 9px, 15px;
    border-radius: 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
    color: #fff;
    border: 1px solid #0D6EFD;
    &:hover {
        background-color: #fff;
        color: #0D6EFD;
        border: 1px solid #0D6EFD;
    }
    @include sm {
        padding: 8px 10px;
    }
}

.navbar_main  {
    position: absolute!important;
    top: 0;
    left: 0;
    background: transparent!important;

    .nformed-btn {
        @include sm {
            padding: 0px!important;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.heroBanner {
    font-family: "Poppins";
    text-align: center;
    width: 100%;
    overflow-x: hidden;
    position: relative;
    z-index: 0;
    padding-top: calc(80px + 3rem)!important;
    &.videoActive {
        &::after {
            @include md {
                height: 25%;
            }
            @include sm {
                height: 18%;
            }
        }
    }
    &::after {
        content: "";
        width: 100%;
        max-height: 400px;
        height: 35%;
        background-color: #028143;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        @include sm {
            height: 25%;
        }
        @include xsm {
            height: 20vh;
        }
    }
    h2{
        font-family: 'Montserrat';
        font-size: 50px;
        font-weight: 700;
        span {
            color: #028143;
        }
        @include lg {
            font-size: 45px;
        }
        @include md {
            font-size: 35px;
        }
    }
    h5 {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.5;
        margin: 1.5rem 0;
    }
    .nformed-btn {
        padding: 12px 25px;
        font-size: 16px;
        border-radius: 999px;
    }
    h4 {
        color: #818181;
        font-size: 16px;
        line-height: 1.3;
        margin: 1.5rem 0;
    }
    .makeSalesContainer {
        width: fit-content;
        margin: 0 auto;
        position: relative;
    }
    .makeSales {
        box-shadow: 5px 4px 12px 0px rgba(33, 37, 41, 0.1215686275);
        border-radius: 24px;
        width: 170px;
        padding: 18px;
        text-align: start;
        position: absolute;
        // right: calc(-170px - 70px);
        right: calc(-100px);
        top: 0px;
        transform: rotate(20deg);
        background-color: #fff;
        p {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
        }
        span {
            transform: rotate(-25deg) translateY(-20px);
            display: block;
        }
        @include xl {
            right: -50px;
            opacity: 0.8;
        }
        @include lg {
            right: 0;
            top: 0px;
            opacity: 0.5;
            width: auto;
        }
        @include md {
            display: none;
        }
    }
    .exploreCompContainer {
        width: fit-content;
        margin: 0 auto;
        position: relative;
        z-index: 0;
        .makeSales {
            right: unset;
            left: calc(-200px - 70px);
            top: unset;
            bottom: -100px;
            transform: rotate(-20deg);
            box-shadow: -5px 1px 12px 0px rgba(33, 37, 41, 0.1215686275);
            span {
                transform: rotate(20deg) translateY(15px);
            }
            @include xl {
                left: -150px;
                opacity: 0.8;
            }
            @include lg {
                left: -100px;
                bottom: -120px;
                opacity: 0.5;
                padding: 10px 25px;
                z-index: -1;
            }
            @include md {
                display: none;
            }
        }
    }
    .BannerVideoBlock {
        background-color: #F1F5F9;
        max-width: 940px;
        margin: 3rem auto 1rem auto;
        border-radius: 20px;
        // padding: 30px 30px 0 30px;
        overflow: hidden;
        border: 10px solid #62C1921F;
        text-align: center;
        position: relative;
        z-index: 0;
        // min-height: 500px;
        // @include lg {
        //     min-height: 400px;
        // }
        // @include md {
        //     min-height: 340px;
        // }
        @include sm {
            // padding: 20px 20px 0 20px;
        }
        .bannerVideoContent {
            position: absolute;
            width: 100%;
            z-index: 1;
            height: 100%;
            left: 0;
            top: 0;
            padding-top: 70px;
            @include md {
                padding-top: 20px;
            }
            @include sm {
                padding-top: 10px;
            }
            @include xsm {
                padding-top: 3vh;
            }
            &::before{
                content: "";
                width: 80%;
                height: 80%;
                border-radius: 50%;
                background: #F0F9F4;
                // filter: blur(118.3219223022461px);
                filter: blur(100px);
                position: absolute;
                left: 10%;
                top: 0;
                z-index: -1;
                @include sm {
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                }
            }
        }
        .phoneImage{
            // max-width: 350px;
            display: block;
            margin: 0 auto;
            // @include sm {
            //     max-width: 95%;
            // }
        }
        h3 {
            font-size: 32px;
            font-weight: 700;
            font-family: 'Montserrat';
            line-height: 1.4;
            @include sm {
                font-size: 24px;
            }
            @include xsm {
                font-size: 4vw;
            }
        }
        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.4;
            @include xsm {
                font-size: 3vw;
            }
        }
        .btnVideoPlay {
            width: fit-content;
            margin-bottom: -60px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            z-index: 2;
            width: 105px;
            height: 105px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #62c19271;
            border-radius: 50%;
            color: #fff;
            font-size: 50px;
            @include sm{
                width: 70px;
                height: 70px;
                font-size: 20px;
            }
            @include xsm {
                width: 15vw;
                height: 15vw;
                font-size: 5vw;
            }
            &:before {
                content: "";
                width: calc(100% - 20px);
                height: calc(100% - 20px);
                background-color: #62C192;
                left: 10px;
                top: 10px;
                position: absolute;
                z-index: -1;
                border-radius: 50%;
            }
        }
    }
}

.heroSubHeader {
    border: 1px solid #D9D9D9;
    width: fit-content;
    border-radius: 999px;
    padding: 4px 10px;
    margin: 1rem auto;
    color: #028143;
    @include sm {
        font-size: 14px;
    }
}

.productSummary {
    background-color: #D9D9D91A;
    font-family: "Poppins";
    .productSBlock {
        img {
            width: 52px;
            height: 52px;
            object-fit: contain;
            display: block;
        }
        h4 {
            font-family: 'Montserrat';
            font-weight: 700;
            line-height: 1.5;
            color: #212529;
        }
        p {
            color: #212529;
            font-size: 16px;
            line-height: 1.3;
            font-weight: 300;
        }
    }
}

.pdsbadge {
    color: #028143;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #D9D9D9;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 99px;
}
.oldAndNew {
    font-family: "Poppins";
    overflow: hidden;
    width: 100%;
    .oanLeft {
        background-color: #fff;
    }
    .oanRight {
        background-color: #62C1921F;
        position: relative;
        z-index: 0;
        padding-left: calc(3rem + 45px)!important;
        @include lg {
            padding-left: 1.5rem !important;
        }
        &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            right: -100%;
            top: 0;
            z-index: -1;
            background-color: #62C1921F;
            @include lg {
                right: unset;
                left: -100%;
                width: 1000%;
                background-color: #dbf0e7;
            }
        }
        
        .versus{
            position: absolute;
            left: -45px;
            // top: 0;
            background-color: #62C192;
            width: 90px;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-family: 'Montserrat';
            font-size: 40px;
            font-weight: 700;
            color: #fff;
            margin-top: -70px;
            @include lg {
                top: -45px;
                left: 50%;
                transform: translateX(-50%);
                margin-top: 0;
            }
        }
    }
    .oldAndNewChild {
        .smallImage {
            width: 52px;
            height: 52px;
            margin-bottom: 2rem;
        }
        h2 {
            font-family: 'Montserrat';
            font-size: 40px;
            font-weight: 700;
            @include xl {
                font-size: 30px;
            }
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                margin: .6rem 0;
                font-size: 18px;
                font-weight: 500;
                padding-left: 30px;
                position: relative;
                span {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .oanRightFooter {
            .nformed-btn {
                padding: 12px 20px;
                border-radius: 999px;
            }
            p {
                color: #818181;
                font-size: 16px;
                margin-top: 6px;
            }
        }
        .old_new_images{
            position: relative;
        }
    }
}

.nfromedGuideHeader {
    h2 {
        color: var(--Off-Black, #212529);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: 'Montserrat';
        font-size: 51px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        @include md {
            font-size: 40px;
        }
        @include sm {
            font-size: 30px;
        }
    }
    p {
        color: var(--Dark-Grey, #818181);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }
}
.NformedGuide {
    background: linear-gradient(0deg, rgba(217, 217, 217, 0.10) 0%, rgba(217, 217, 217, 0.10) 100%), #FFF;
    .productResearch {
        h3 {
            color: var(--Off-Black, #212529);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: 'Montserrat';
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            @include md {
                font-size: 35px;
            }
            @include sm {
                font-size: 25px;
            }
        }
        p {
            color: var(--Off-Black, #212529);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 140%;
            @include md {
                font-size: 17px;
            }
            @include sm {
                font-size: 15px;
            }
        }
        .nformed-btn {
            padding: 12px 25px;
            border-radius: 999px;
        }

        .psRight {
            position: relative;
            z-index: 0;
            // width: calc(400px);
            // height: calc(400px);
            // position: absolute;
            // background-color: #212529;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
                border-radius: 5px;
            }
            @include lg {
                margin: 3.5rem auto;
            }
            @include sm {
                // width: 300px;
                // height: 300px;
            }
            @include xsm {
                // width: calc(230px);
                // height: calc(230px);
            }
            // .circleBg {
            //     width: 100%;
            //     height: 100%;
            //     position: absolute;
            //     background-color: #333;
            //     border-radius: 50%;
            //     z-index: -1;
            // }
            &:before {
                // content: "";
                width: calc(400px + 30px);
                height: calc(400px + 30px);
                position: absolute;
                left: -15px;
                top: -15px;
                border-radius: 50%;
                // border: 1px dashed #212529;
                border: 1px solid #212529;
                z-index: -2;
                // animation: rotate;
                // animation-duration: 20s;
                // animation-iteration-count: infinite;
                // animation-direction: alternate;
                // background-color: #ccc;
                @include sm {
                    width: calc(300px + 30px);
                    height: calc(300px + 30px);
                }
                @include xsm {
                    width: calc(230px + 30px);
                    height: calc(230px + 30px);
                }
            }
            &:after {
                // content: "";
                width: calc(400px + 60px);
                height: calc(400px + 60px);
                position: absolute;
                left: -30px;
                top: -30px;
                border-radius: 50%;
                border: 1px solid #212529;
                z-index: -3;
                @include sm {
                    width: calc(300px + 60px);
                    height: calc(300px + 60px);
                }
                @include xsm {
                    width: calc(230px + 60px);
                    height: calc(230px + 60px);
                }
            }
            
        }
    }
}

.stacksUp {
    background: #028143;
    .nfromedGuideHeader{
        position: relative;
        z-index: 2;
        .heroSubHeader{
            svg {
                path{
                    stroke: #fff;
                }
            }
        }
    }
    h2 {
        color: #fff;
        span {
            color: rgba(255, 255, 255, 0.60);;
        }
    }
    .stackTableDiv {
        border-radius: 15px;
        border: 12px solid rgba(98, 193, 146, 0.20);
        background: lightgray 50%;
        position: relative;
        z-index: 0;
        &::before{
            content: "";
            width: 608px;
            height: 608px;
            position: absolute;
            left: 0;
            top: -220px;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
            border-radius: 8000px;
            background: #62C192;
            filter: blur(117px);
            max-width: 100%;
        }
        & > div{
            border-radius: 15px;
        }
        .table{
            font-family: 'Poppins';
            table-layout: fixed;
            min-width: 800px;
            margin-bottom: 0;
            thead{
                tr{
                    th{
                        // padding-top: 0;
                        // padding-bottom: 0;
                        padding-left: 2rem;
                        padding-right: 0;
                        background-color: transparent;
                        text-align: center;
                        &:first-child{
                            position: sticky;
                            left: 0;
                            background-color: #fff;
                            z-index: 99;
                            @include md {
                                position: relative;
                            }
                        }
                    }
                }
            }   
            tbody{
                tr{
                    td{
                        padding-top: 0;
                        padding-bottom: 0;
                        padding-left: 2rem;
                        padding-right: 0;
                        background-color: transparent;
                        text-align: center;
                        vertical-align: middle;
                        color: #777;
                        font-weight: 700;
                        &:first-child{
                            position: sticky;
                            left: 0;
                            background-color: #fff;
                            z-index: 99;
                            padding-left: 0;
                            text-align: start;
                            color: #777;
                            font-weight: 600;
                            // position: relative;
                            @include md {
                                position: relative;
                            }
                            span{
                                padding-left: 3rem;
                            }
                            i{
                                position: absolute;
                                left: 1.7rem;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }
        }
    }
}

.custom-tooltip {
    background-color: #eee;
    color: #000;
    border-radius: 5px;
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    .tooltip-inner{
        padding: .5rem;
        background-color: #eee;
        color: #000;
        text-align: left;
    }
  }
  
  .custom-tooltip .tooltip-arrow::before {
    border-top-color: #eee;
  }


.testiMonial {
    background: url('Assets/new-landing-page-images/testimonial_background1.jpg');
    background-color: #62C1921F!important;
    background-blend-mode: multiply;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    .testimonialBlock.card {
        border-radius: 12px;
        overflow: hidden;
        border: 0;
        // height: 306px;
        height: 100%!important;
        margin-top: auto;
        .card-header, .card-footer {
            border: 0;
            background: white;
            border-radius: 0;
            color: #FFBE4C;
            font-size: 22px;
        }
        .card-body {
            p {
                color: var(--Off-Black, #212529);
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                line-height: 140%; 
                margin-bottom: 0;
            }
        }
        .card-footer  {
            display: flex;
            align-items: center;
            img {
                width: 48px;
                height: 48px;
                object-fit: cover;
            }
            .testUserDtl {
                padding-left: 15px;
                width: calc(100% - 48px);
                h6 {
                    color: var(--Off-Black, #212529);
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%;
                    margin: 0;
                }
                p {
                    color: var(--Dark-Grey, #818181);
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%;
                    margin: 0;
                }
            }
        }
    }
    .slick-track {
        display: flex !important;
    }
    
    .slick-slide {
        height: inherit !important;
        & > div {
            height: 100%;
        }
    }
    .slick-dots {
        transform: translateY(20px);
        li {
            width: 20px;
            height: 20px;
            button {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                &::before {
                    font-size: 15px;
                    width: 20px;
                    height: 20px;
                }
            }
            &.slick-active {
                button {
                    &::before {
                        color: #028143;
                    }
                }
            }
        }
    }
}

.planTab {
    width: fit-content;
    border: 0;
    background: rgba(217, 217, 217, 0.15);
    padding: 15px 15px;
    border-radius: 999px;
    margin: 0 auto;
    .nav-item {
        .nav-link {
            color: var(--Gray-60, #475569);
            font-family: "Montserrat";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: -0.112px;
            border: 0;
            small {
                border-radius: 1234px;
                background: var(--Destructive-5, #FFF1F2);
                padding: 5px 15px;
            }
            &[aria-selected="true"] {
                box-shadow: 0px 4px 8px -2px rgba(23, 23, 23, 0.10), 0px 2px 4px -2px rgba(23, 23, 23, 0.06);
                border-radius: 999px;
                background-color: #fff;
                padding: 10px 10px;
            }
        }
    }
}

.planCard {
    border-radius: 12px;
    border: 3px solid rgba(217, 217, 217, 0.22);
    background: var(--White, #FFF);
    height: 100%;
    position: relative;
    // padding-bottom: 80px!important;
    .planCardTop {
        h4 {
            color: var(--Light-Green, #62C192);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: 'Montserrat';
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
        }
        h6 {
            color: var(--Gray-60, #475569);
            // text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: 'Montserrat';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            .planAmount {
                color: var(--Off-Black, #212529);
                text-align: center;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: 'Montserrat';
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
            }
        }
        p {
            color: var(--Dark-Grey, #818181);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 140%;
        }
        border-bottom: 1px solid #D9D9D9;
        padding-bottom: .5rem;
        margin-bottom: 1.5rem;
        .nformed-btn {
            padding: 12px 30px;
            border-radius: 999px;
            // position: absolute;
            bottom: 20px;
            // left: 50%;
            // transform: translateX(-50%);
            display: block;
            margin-left: auto;
            margin-right: auto;
            // width: calc(100% - 3rem)!important;
        }
        .nformed-btn.get-started{
            background-color: rgba(13, 110, 253, 0.13);
            border-color: rgba(13, 110, 253, 0.13);
            color: #0D6EFD;
        }
    }
    .planCardBottom {
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                position: relative;
                padding-left: 30px;
                margin: .8rem 0;
                color: var(--Dark-Grey, #818181);
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                .icon {
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    border-radius: 50%;
                    background-color: #8181816b;
                    font-size: 10px;
                    svg {
                        position: absolute;
                        top: 5px;
                    }
                }
                .check {
                    color: #fff;
                    background-color: #62C192;
                }
            }
        }
        // .nformed-btn {
        //     padding: 12px 30px;
        //     border-radius: 999px;
        //     position: absolute;
        //     bottom: 20px;
        //     left: 50%;
        //     transform: translateX(-50%);
        //     width: calc(100% - 3rem)!important;
        // }
        // .nformed-btn.get-started{
        //     background-color: rgba(13, 110, 253, 0.13);
        //     border-color: rgba(13, 110, 253, 0.13);
        //     color: #0D6EFD;
        // }
    }
    &.active {
        border: 3px solid #62C192;
        background-color: rgba(98, 193, 146, 0.08);;
    }
}

.freeTrialBlock {
    border-radius: 30px;
    background: var(--Light-Green, #62C192);
    text-align: center;
    img {
        width: 52px;
        height: 52px;
        object-fit: contain ;
    }
    h3 {
        color: var(--White, #FFF);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: 'Montserrat';
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        @include md {
            font-size: 41px;
        }
        @include sm {
            font-size: 31px;
        }
    }
    p {
        color: var(--White, #FFF);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        max-width: 800px;
        margin: 0 auto;
    }
    .nformed-btn {
        padding: 12px 28px;
        border-radius: 999px;
        margin-top: 1rem;
    }
}

.freeTrial {
    margin-bottom: -150px;
    position: relative;
    z-index: 3;
}

.NewFooter {
    background: var(--Off-Black, #212529);
    padding-top: calc(150px + 3rem)!important;
    font-family: Poppins;
    color: #fff;
    &.innerPageFooter {
        padding-top: 50px!important;
        padding-bottom: 50px!important;
    }
    p {
        color: var(--Dark-Grey, #818181);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            margin: .5rem 0;
            a {
                color: #fff;
                text-decoration: none;
                font-size: 18px;
                font-weight: 400;
                @include sm {
                    font-size: 16px;
                }
            }
        }
    }
    h3 {
        font-family: 'Montserrat';
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        @include sm {
            font-size: 28px;
        }
    }
    .nformed-btn {
        padding: 12px 25px;
        border-radius: 999px;
    }
    .scicon {
        a {
            margin-left: .8rem;
            margin-right: .8rem;
            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.mb-7 {
    margin-bottom: 6rem!important;
    @include lg {
        margin-bottom: 2rem!important;
    }
}

.aspect-\[2\/1\] {
    position: absolute!important;
    top: -100px;
    left: 0;
    z-index: -9;
    height: calc(100% + 100px);
    opacity: 0.1;
}

// @keyframes rotate{
//     from{ transform: rotate(-360deg); }
//     to{ transform: rotate(360deg); }
// }

.nformed-btn.sign-in-btn{
    background-color: rgba(13, 110, 253, 0.13);
    border-color: rgba(13, 110, 253, 0.13);
    color: #0D6EFD;
    padding: 12px 20px;
    border-radius: 999px;
    line-height: 1;
    text-align: center;
}

.copyrightLinks  {
    margin: 0;
    a{
        text-decoration: none;
        color: #fff;
        font-weight: 400;
        font-size: 12px;
    }
}

.newsLetter {
    .newsLetterBlock{
        position: relative;
        .form-control {
            padding-right: 120px;
            height: 50px;
            border-radius: 999px;
            background-color: transparent;
            box-shadow: none;
            border: 1px solid #0D6EFD;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            // min-width: 300px;
            &::placeholder {
                color: #fff;
                opacity: 0.8;
                font-weight: 300;
                letter-spacing: 0.5px;
            }
        }
        .btn {
            position: absolute;
            right: 5px;
            height: calc(100% - 10px);
            top: 5px;
            width: 110px;
            // display: flex;
            // align-items: center;
            font-size: 14px;
            justify-content: center;
        }
    }
}

.discountBadge {
    border-radius: 1234px;
    background: var(--Destructive-5, #FFF1F2);
    padding: 5px 15px;
}

.cursor-pointer {
    cursor: pointer;
}



span.best_seller {
    position: absolute;
    top: 0;
    left: 50%;
    font-size: 11px;
    letter-spacing: 1px;
    font-family: 'Poppins';
    text-transform: uppercase;
    font-weight: 700;
    padding: 3px 7px;
    border-radius: 7px 0 7px 0;
    color: var(--bs-primary);
    background: #62c1927a;
    transform: translateX(-50%);
    border-radius: 0 0 7px 7px;
}

.trial_image {
    position: absolute;
    width: 81px;
    right: 10px;
    top: 10px;
    // filter: grayscale(1);
}

.planCard.active .trial_image {
    filter: none;
}


@media only screen and (min-width: 992px) and (max-width: 1350px){
    .planCard .planCardTop h4 {
        max-width: 200px;
    }
}
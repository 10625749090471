.avuheading{
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    font-size: 39px;
    position: relative;
}
.avuheading h2{
    font-weight: 700;
}
.avusubHeading {
    position: relative;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #818181;
}
.line{
    height: 2px;
    width: 50%;
    background-color: antiquewhite;
    position: absolute;
    z-index: 999;
}
.chartline::after {
    content: '';
    width: 510px;
    height: 3px;
    background-color: #ACACAC;
    position: absolute;
    top: 52%;
    right: -11%;
    z-index: -2;
}
/* Start responsive section */
@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .chartline::after {
        content: '';
        width: 445px;
        height: 3px;
        background-color: #ACACAC;
        position: absolute;
        top: 52%;
        right: -12%;
        z-index: -2;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
   .chartline{
    display: none;
   }
   .chartline::after {
    content: '';
    width: 0px;
    height: 3px;
    background-color: #ACACAC;
    position: absolute;
    top: 52%;
    right: -12%;
    z-index: -2;
}
.leftarrow{
    display: none;
}
.rightarrow{
    display: none;
}
    
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .chartline{
     display: none;
    }
    .chartline::after {
     content: '';
     width: 0px;
     height: 3px;
     background-color: #ACACAC;
     position: absolute;
     top: 52%;
     right: -12%;
     z-index: -2;
 }
 .leftarrow{
     display: none;
 }
 .rightarrow{
     display: none;
 }
     
 }
@media screen and (min-width: 300px) and (max-width: 767px) {
    .avusubHeading p br{
        display: none;
    }
    .chartline::after {
        content: '';
        width: 0px;
        height: 3px;
        background-color: #ACACAC;
        position: absolute;
        top: 52%;
        right: -12%;
        z-index: -2;
    }
    .leftarrow{
        display: none;
    }
    .rightarrow{
        display: none;
    }
}

/* End REsponsive Section */

/* @media screen and (min-width: 300px) and (max-width: 992px) {
    .subHeading {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
    }
    .subHeading p br{
        display: none;
    }
    .chartline::after {
        content: url('');
        width: 0px;
        height: 0px;
        background-color: #ACACAC;
        position: absolute;
        top: 52%;
        right: -11%;
        z-index: -2;
    }
    .leftarrow{
        display: none;
    }
    .rightarrow{
        display: none;
    }
    .chartimg .mainimg{
        width: 100%;
    }
  }
@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .chartline::after {
        content: url('');
        width: 440px;
        height: 3px;
        background-color: #ACACAC;
        position: absolute;
        top: 52%;
        right: -11%;
        z-index: -2;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .chartline::after {
        content: url('');
        width: 0px;
        height: 3px;
        background-color: #ACACAC;
        position: absolute;
        top: 52%;
        right: -11%;
        z-index: -2;
    }
    .leftarrow{
        display: none;
    }
    .rightarrow{
        display: none;
    }
} */
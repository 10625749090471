.tabSwipe .tab-content{
    position: relative;
    min-height: 560px;
}
.tabSwipe .tab-content .bgTab{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.tabInsideImage{
    width: 81%;
    position: relative;
    /* z-index: 0; */
    left: 7%;
    margin-top: 12%;
    border-radius: 10px;
    /* min-height: 360px; */
    object-fit: contain;
}
.sellerTab.nav-pills .nav-link{
    border-radius: 8px;
    border: 1px solid rgba(79, 77, 77, 0.20);
    display: block;
    width: 100%;
    margin: 5px 0;
    color: #4F4D4D;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 27.32px;
    letter-spacing: 0.16px;
    padding: 10px 20px;
}
.sellerTab.nav-pills .nav-link.active{
    background: rgba(0, 154, 89, 0.08);
    color: #009A59;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 27.32px; 
    letter-spacing: 0.2px;
}
.tabSwipe h3 {
    color: #2E2E2E;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 27.32px;
    letter-spacing: 0.24px;
}
.tabSwipe h2 {
    color: #191A1B;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: 0.4px;
}
.sheading{
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    font-size: 39px;
}
.ssubheading{
    font-size: 17px;
    font-weight: 300;
    margin-top: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #818181;
}

.salesContainer{
    padding: 23px;
}

/* Start responsive section */
@media screen and (min-width: 1200px) and (max-width: 1399px) {
    
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .sheading {
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        font-size: 29px;
    }
    .salesImage img{
        margin-top: 64px;
    }
    
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    
     
 }
 @media screen and (min-width: 575px) and (max-width: 767px) {
    .sheading {
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        font-size: 32px;
        text-align: center;
    }
 }



/* End REsponsive Section */
@media screen and (min-width: 300px) and (max-height: 574px){
    .sheading {
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        text-align: center;
    }
    p.ssubheading {
        font-size: 17px;
        font-weight: 300;
        text-align: center;
    }
}
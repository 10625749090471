.rc-slider-rail {
  border-radius: 8px;
  background-color: #e6e6e6;
  height: 8px;
}
.rc-slider-track,
.rc-slider-tracks {
  background-color: var(--primary);
  height: 8px;
  border-radius: 8px;
}
.rc-slider-step {
  height: 8px;
}
.rc-slider-dot {
  width: 2px;
  height: 8px;
  background: var(--primary);
  border: 0;
  border-radius: 0;
  bottom: 0;
  &:first-child {
    opacity: 0;
  }
  &:last-child {
    opacity: 0;
  }
}

.rc-slider-handle {
    width: 26px;
    height: 26px;
    background-color: #fff;
    opacity: 1;
    border: 6px solid var(--primary);
    top: 0;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #62C191;
//   opacity: 0.85;
  box-shadow: 0 0 5px #000;
}

.rc-slider-handle:hover {
    border-color: #62C191;
    // opacity: 0.85;
}

// .rc-slider-mark-text{
//     display: none;
// }

.rc-slider-handle:focus-visible {
    border-color: #62C191;
      box-shadow: 0 0 5px #000;
}

// .rc-tooltip-hidden{
//     display: block!important;
// }
.rc-tooltip-arrow {
    opacity: 0!important;
}

.rc-tooltip{
    padding-bottom: 5px!important;
    opacity: 1!important;
}

.rc-tooltip-inner {
    background-color: var(--primary)!important;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
}

.rc-slider-mark-text-active {
    color: var(--primary);
}
.rc-slider-mark{
    top: 25px;
}
.sandiheading h2{
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    font-size: 39px;
}
.headingSubContainer{
    z-index: 9;
}
.sellerisubheading p{
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 28px;
letter-spacing: 0.02em;
color: #818181;
}
.sellerimgContainer img{
    width: 100%;
}
/* Start responsive section */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .sellerisubheading p br{
        display: none;
    }
    .sandiheading h2 {
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        text-align: justify;
        font-size: 30px;
    }
}
@media screen and (min-width: 300px) and (max-width: 767px) {
    .sandiheading h2{
        text-align: center;
    } 
    .sellerisubheading p{
        text-align: center;
        
    }
    .sandiheading h2 {
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        text-align: center;
        font-size: 30px;
    }
}
/* End REsponsive Section */
/* @media screen and (min-width: 300px) and (max-height: 767px){
    .heading {
        text-align: center;
    }
} */
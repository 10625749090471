
.DiscoverDecide h3{
    color: #191A1B;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.DiscoverDecide p{
    color: rgba(0, 0, 0, 0.50);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0;
}
.DiscoverDecide ul.elevateUl li .ulIcon svg  path{
    fill: #099359;
    fill-opacity: 1;
}
.btn.discover-btn {
    border-radius: 100px;
    background: #009A59;
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    padding: 10px 25px;
}
.btn.discover-btn:hover{
    background-color: #fff;
    border-color: #009A59;
    color: #009A59;
}
.brandDashboard.tabSwipe .tabInsideImage {
    width: 74%;
    left: 13%;
    margin-top: 8.5%;
    border-radius: 0;
    max-height: 340px;
    object-fit: cover;
}
.brandDashboard.tabSwipe .tab-content{
    min-height: 450px;
}
.brandDashboard .sellerTab.nav-pills .nav-link{
    display: block;
    width: 100%;
    margin: 5px 0;
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.22px;
    padding-left: 50px;
    border: 0;
    position: relative;
}
.brandDashboard .sellerTab.nav-pills .nav-link .ico {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: #D9D9D9;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 10px;
}
.brandDashboard .sellerTab.nav-pills .nav-link.active{
    background: transparent;
    color: #009A59;
}

@media only screen and (max-width: 991px){
    .tabSwipe .tab-content{
        min-height: auto!important;
        margin-bottom: 80px;
    }
    .brandDashboard.tabSwipe .tab-content{
        min-height: auto!important;
        margin-bottom: 50px;
    }
}
.bannerLeftHeader{
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}

.bannerLeftHeader p {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    margin-top: 12px;
}
.bannerLeftHeader h2{
    color: #198754;
    font-family: 'Montserrat', sans-serif;
}
.stayWithCard{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #198754;
    width: fit-content;
    padding: 0px 15px;
    border-radius: 8px;
    margin-top: 30px;
}
.stayWith{
    display: flex;
    align-items: center;
    justify-content: center;
}
.stayWithCard h1 {
    font-family: Prata;
    font-size: 25px;
    color: #ffff;
    margin-top: 6px;
}
.stayWithCard img{
    width: 150px;
}
.banner{
    background-image: url("../cesBanner.png");
    background-size: 45%;
    background-position:right;
    background-repeat: no-repeat;
    background-origin: content-box;
}
.bannerRight img{
    height: 500px;
    border-radius: 5px;
}
.bannerRight{
    align-items: flex-end;
    justify-content: flex-end;
}
.counters{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 17px -1px rgba(0,0,0,0.61);
    width: 100%;
    padding: 19px;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.baseLinegreen{
    height: 2px;
    width: 100%;
    background-color: #25CD44;
    border-radius: 5px;
}
.baseLinewarning{
    height: 2px;
    width: 100%;
    background-color: #FFB339;
    border-radius: 5px;
}
.baseLinered{
    height: 2px;
    width: 100%;
    background-color: #FF2661;
    border-radius: 5px;
}

.baseLineviolet{
    height: 2px;
    width: 100%;
    background-color: #8000FF;
    border-radius: 5px;
}

    .signup {
        width: 165px;
        border-radius: 30px;
        font-family: 'Poppins';
        font-weight: 700;
    }
    .counter h3{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-size: 30.0462px;
        line-height: 69px;
    }

.powerBody{
    margin-top: 115px;
}
.powerBodyHeader{
font-family: 'Montserrat', sans-serif;
font-size: 39px;
margin-bottom: 25px;
}
.powerBodyText{
    position: relative;
    color: #198754;
    font-size: 20px;
    font-weight: 400;
}
.powerBodyText::after{
    content: "";
    position: absolute;
    max-width: 200px;
    width: 100%;
    height: 2px;
    background-color: #198754;
    bottom: 8px;
    right: 296px;
}
.powerBodyText::before{
    content: "";
    position: absolute;
    max-width: 200px;
    width: 100%;
    height: 2px;
    background-color: #198754;
    bottom: 8px;
    left: 296px;
}
.tracking{
    background-color: rgba(25, 135, 84, 0.15);
}
.card_header h4{
    color: #009A59;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
}
.card{
    height: 350px;
}
.card_header{
    margin: 20px 0px;
}
.tracking_padding{
    padding: 150px 0px;
}
.card_text p{
    font-size: 21px;
    font-family: Roboto;
    line-height: 32px;
    font-weight: 400;
    color: #555555;
}
.signupSection{
    background-image: url("../IMG.jpg");
    background-size: 60%;
    background-position:left;
    background-repeat: no-repeat;
    background-origin: content-box;

}
.signup_form{
    background-color: #FFFFFF;
    -webkit-box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
-moz-box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
width: 70%;
margin: 50px 0px;
padding: 35px;
border-radius: 10px;
}
.signup_form h2{
    font-family: 'Montserrat', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 54px;
color: #198754;
}

.form_header p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #474747;
    margin-bottom: 25px;
}
label.form-check-label {
    display: inline-block;
    margin: 0 !important;
}
.form_bottom {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    color: #009A59 !important;
    text-align: center;
    margin: 20px 0px;
}
.contact_us_body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px dashed #009A59;
    border-radius: 10px;
    margin: 45px 0px;
    padding: 40px 20px;
}
.contact_us_body .header{
    font-family: 'Montserrat', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 32px;
/* identical to box height, or 89% */

display: flex;
align-items: center;

color: #009A59;
}

.contact_us_body .subHeader{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #8A8A8A;

}

.contact_us_body .email{
    font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 133% */

display: flex;
align-items: center;

}

.contact_us_body .email span{
    font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 133% */

display: flex;
align-items: center;

color: #009A59;
}

/* Responsive start */

@media screen and (min-width: 768px) and (max-width: 991px) {
    .bannerLeftHeader p {
        font-family: "Poppins";
        font-size: 14px;
        line-height: 22px;
        font-weight: 600;
        margin-top: 9px;
    }
    .bannerLeftHeader h2 {
        color: #198754;
        font-size: 21px;
    }
    .counter h3{
        font-size: 19px;
    }
    .powerBody {
        margin-top: 40px;
    }
    .powerBodyHeader {
        font-family: 'Montserrat', sans-serif;
        font-size: 27px;
        margin-bottom: 20px;
    }
    .powerBodyText::after {
        content: "";
        position: absolute;
        max-width: 130px;
        width: 100%;
        height: 2px;
        background-color: #198754;
        bottom: 8px;
        right: 80px;
    }
    .powerBodyText::before {
        content: "";
        position: absolute;
        max-width: 130px;
        width: 100%;
        height: 2px;
        background-color: #198754;
        bottom: 8px;
        left: 80px;
    }
    .card-icon img{
        width: 30px;
    }
    .card_header h4 {
        color: #009A59;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 7px;
    }
    .card_text p {
        font-size: 14px;
        font-family: Roboto;
        line-height: 23px;
        font-weight: 400;
        color: #555555;
    }
    .card {
        height: 307px;
    }
    .signup_form h2 {
        font-size: 23px;
    }
    .signup_form {
        width: 93%;
    }
    .contact_us_body .header {
        font-size: 22px;
        line-height: 8px;
    }
    .contact_us_body .subHeader {
        font-size: 15px;
    }
    .contact_us_body .email {
        font-size: 18px;
        line-height: 0px;
        
    }
    .contact_us_body .email span {
        font-size: 20px;
        line-height: 0px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .powerBodyText::before {
        max-width: 130px;
    }
    .powerBodyText::after {
        max-width: 130px;
    }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .powerBodyText::before {
        left: 209px;
        max-width: 110px;
    }
    .powerBodyText::after {
        right: 209px;
        max-width: 110px;
    }
    .card-icon img{
        width: 30px;
    }
    .card_header h4 {
        font-size: 20px;
        line-height: 0px;

    }
    .card_text p {
        font-size: 16px;
        line-height: 27px;
    }
    .card {
        height: 295px;
    }
    .bannerLeftHeader h2 {
        font-size: 20px;
    }
    .bannerLeftHeader p {
        font-size: 16px;
        line-height: 27px;
        margin-top: 17px;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .bannerLeftHeader {
        height: 500px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 20px;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .banner{
        background-image: none;
       
    }
    .powerBodyHeader {
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        margin-bottom: 21px;
    }
    .powerBodyText::before {
        left: 79px;
        max-width: 50px;
    }
    .powerBodyText::after {
        right: 79px;
        max-width: 50px;
    }
    .signup_form h2 {
        font-size: 23px;
    }
    .contact_us_body .header {
        font-size: 25px;
        line-height: 25px;
    }
    .contact_us_body .subHeader {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .contact_us_body .email {
        font-size: 16px;
        line-height: 10px;
    }
    .contact_us_body .email span {
        font-size: 16px;
        line-height: 10px;
    }
    .bannerLeftHeader p {
        font-family: "Poppins";
        font-size: 16px;
        line-height: 25px;
        margin-top: 14px;
    }
}
@media screen and (min-width: 525px) and (max-width: 575px) {
    .banner{
        background-image: none;
    }
    .stayWithCard h1 {
        font-size: 15px;
    }
    .stayWithCard img{
        width: 90px;
    }
    .powerBodyHeader {
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        margin-bottom: 21px;
    }
    .powerBodyText::before {
        left: 79px;
        max-width: 50px;
    }
    .powerBodyText::after {
        right: 79px;
        max-width: 50px;
    }
    .bannerLeftHeader p {
        font-family: "Poppins";
        font-size: 16px;
        line-height: 26px;
        font-weight: 600;
        margin-top: 11px;
    }
    .bannerRight {
        align-items: flex-end;
        justify-content: center;
    }
    .powerBody {
        margin-top: 25px;
    }
    .card {
        height: 350px;
        margin-top: 20px;
    }
    .tracking_padding {
        padding: 50px 0px;
    }
    .signup_form h2 {
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
    }
    .contact_us_body .header {
        font-size: 25px;
        line-height: 20px;
    }
    .contact_us_body .subHeader {
        font-size: 15px;
        line-height: 20px;
        text-align: center;
    }
    .contact_us_body .email {
        font-size: 17px;
        line-height: 11px;
    }
    .contact_us_body .email span {
        font-size: 17px;
        line-height: 10px;
    }
    .counter h3{
        font-size: 17px;
        font-weight: 600;
    }
}
@media screen and (min-width: 400px) and (max-width: 524px) {
    
    .stayWithCard h1 {
        font-size: 15px;
    }
    .stayWithCard img{
        width: 90px;
    }
    .banner{
        background-image: none;
    }
    .powerBodyText::before {
        left: 10px;
        max-width: 50px;
    }
    .powerBodyText::after {
        right: 10px;
        max-width: 50px;
    }
    .powerBodyText {
        position: relative;
        color: #198754;
        font-size: 20px;
        font-weight: 400;
    }
    .powerBodyHeader {
        font-family: 'Montserrat', sans-serif;
        font-size: 23px;
        margin-bottom: 25px;
    }
    .powerBody {
        margin-top: 22px;
    }
    .bannerLeftHeader p {
        font-size: 14px;
        line-height: 24px;
        margin-top: 14px;
    }
    .signup_form h2 {
        font-size: 25px;
        line-height: 25px;
    }
    .contact_us_body .header {
        font-size: 25px;
        line-height: 20px;
    }
    .contact_us_body .subHeader {
        font-size: 15px;
        line-height: 20px;
        text-align: center;
    }
    .contact_us_body .email {
        font-size: 17px;
        line-height: 11px;
    }
    .contact_us_body .email span {
        font-size: 17px;
        line-height: 10px;
    }
    .card {
        height: 281px;
        margin-top: 20px;
    }
    .card_text p {
        font-size: 16px;
        font-family: Roboto;
        line-height: 25px;
        font-weight: 400;
        color: #555555;
    }
    .card_header h4 {
        color: #009A59;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 10px;
    }
    .tracking_padding {
        padding: 20px 0px;
    }
    .signup_form {
        background-color: #FFFFFF;
        box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
        width: 85%;
        margin: 50px 0px;
        padding: 35px;
        border-radius: 10px;
    }
    .counter h3{
        font-size: 17px;
        font-weight: 600;
    }    
    
}
@media screen  and (max-width: 399.99px) {
    .counter h3{
        font-size: 17px;
        font-weight: 600;
    }
    .banner{
        background-image: none;
    }
    .stayWithCard h1 {
        font-size: 15px;
    }
    .stayWithCard img{
        width: 90px;
    }
    .powerBodyText::before {
        display: none;
    }
    .powerBodyText::after {
        display: none;
    }
    .powerBodyText {
        position: relative;
        color: #198754;
        font-size: 20px;
        font-weight: 400;
    }
    .powerBodyHeader {
        font-family: 'Montserrat', sans-serif;
        font-size: 23px;
        margin-bottom: 25px;
    }
    .powerBody {
        margin-top: 22px;
    }
    .contact_us_body .header {
        font-size: 25px;
        line-height: 20px;
    }
    .contact_us_body .subHeader {
        font-size: 15px;
        line-height: 20px;
        text-align: center;
    }
    .contact_us_body .email {
        font-size: 17px;
        line-height: 11px;
    }
    .contact_us_body .email span {
        font-size: 17px;
        line-height: 10px;
    }
    .powerBodyText::before {
        left: 10px;
        max-width: 50px;
    }
    .powerBodyText::after {
        right: 10px;
        max-width: 50px;
    }
    .powerBodyText {
        position: relative;
        color: #198754;
        font-size: 20px;
        font-weight: 400;
    }
    .powerBodyHeader {
        font-family: 'Montserrat', sans-serif;
        font-size: 23px;
        margin-bottom: 25px;
    }
    .powerBody {
        margin-top: 22px;
    }
    .bannerLeftHeader p {
        font-size: 14px;
        line-height: 24px;
        margin-top: 14px;
    }
    .signup_form h2 {
        font-size: 25px;
        line-height: 25px;
    }
    .contact_us_body .header {
        font-size: 25px;
        line-height: 20px;
    }
    .contact_us_body .subHeader {
        font-size: 15px;
        line-height: 20px;
        text-align: center;
    }
    .signup_form {
        background-color: #FFFFFF;
        box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
        width: 100%;
        margin: 50px 0px;
        padding: 35px;
        border-radius: 10px;
    }
    .tracking_padding {
        padding: 10px 0px;
    }
    .card-icon img{
        width: 40px;
    }
    .card_header h4 {
        color: #009A59;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 5px;
    }
    .card_text p {
        font-size: 17px;
        font-family: Roboto;
        line-height: 25px;
        font-weight: 400;
        color: #555555;
    }
    .card {
        height: 281px;
        margin-top: 20px;
    }
}
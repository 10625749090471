@import "../../mixin.scss";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Play:wght@400;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primary: #20a76e;
$secondary: #0d6efd;
// $font2: "Audiowide", sans-serif;
$font2: "Play", sans-serif;
$font: "Poppins";

:root {
  --primary: #20a76e;
}

.signUpFlow {
  min-height: 100vh;
  display: flex;
  align-items: center;
  font-family: $font;
  font-weight: 400;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font2;
    letter-spacing: 0.6px;
    font-weight: 400;
  }
  .spLogo {
    max-width: 205px;
    position: absolute;
    top: -20px;
    @include cl {
      //   top: -60px;
    }
  }
  .signUpLeft {
    .custom-field {
      .form-control {
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid #444;
        box-shadow: none;
        &:focus {
          border-color: $primary;
        }
      }
    }
    .form-label {
      color: #999;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 0;
      font-family: $font2;
    }
    .form-control {
      color: #6b6b6b;
      font-size: 16px;
      line-height: normal;
      &.error {
        border-color: var(--bs-danger) !important;
      }
      &::placeholder {
        opacity: 0.5;
      }
    }
    .form-check-input {
      &:checked {
        background-color: $primary;
        border-color: $primary;
      }
      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(32 167 110 / 29%);
      }
    }
    h5 {
      color: #000;
      font-size: 30px;
      font-weight: 600;
    }
    p {
      color: #000;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 2.5rem;
    }
    .customSearch {
      position: relative;
      i {
        position: absolute;
        left: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        @include sm {
          height: 45px;
        }
      }
      .form-control {
        padding-left: 35px;
        padding-right: 120px;
        border-radius: 10px;
        height: 45px;
        box-shadow: none;
        &:focus {
          border-color: $primary;
        }
        @include sm {
          padding-right: 0px;
        }
      }
      .btn {
        position: absolute;
        height: 100%;
        right: 0;
        top: 0;
        width: 120px;
        font-size: 14px;
        font-weight: 400;
        border-radius: 0 10px 10px 0;
        @include sm {
          position: static;
          width: 100%;
          border-radius: 10px;
          margin-top: 10px;
        }
      }
    }
    .searchListDiv {
      border-radius: 10px;
      background: #eff6f3;
      padding: 15px;
      margin: 15px 0;
      .searchListBlock {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
        border-radius: 10px;
        background: rgba(60, 184, 128, 0.22);
        padding: 10px;
        .img {
          width: 55px;
          height: 55px;
          border-radius: 10px;
          background-color: #fff;
          padding: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
        p {
          margin: 0;
          width: calc(100% - 75px);
          padding: 0 15px;
          color: #4b4b4b;
          font-size: 14px;
          font-weight: 400;
        }
        i {
          color: $primary;
          width: 20px;
          display: block;
        }
      }
    }
    .addedProducts {
      border-radius: 10px;
      background: #f4f6f6;
      padding: 15px;
      .addedProductBlock {
        display: flex;
        align-items: center;
        border-radius: 8px;
        background: #fff;
        padding: 10px;
        img {
          width: 50px;
          height: 50px;
          object-fit: contain;
        }
        .btn {
          font-size: 12px;
          width: 80px;
        }
        p {
          width: calc(100% - 130px);
          padding: 0 10px;
        }
      }
    }
  }
  .signUpright {
    border-radius: 15px;
    // background: #20a76e;
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    img {
      margin-bottom: 3rem;
    }
    .content {
      padding-left: 2rem;
      margin-top: 1.35rem;
    }
    h3 {
      color: #fff;
      font-size: 36px;
      font-weight: 600;
    }
    p {
      color: #fff;
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.moreDetails {
  position: fixed;
  right: 0;
  width: calc(50% - 00px);
  height: 100%;
  top: 0;
  height: 100vh;
  // overflow-y: auto;
  // background: #f4f6f6;
  background: #f4f6f67d;
  backdrop-filter: blur(200px);
  @include xl {
    width: calc(50% - 30px);
  }
  @include lg {
    position: static;
    width: 100%;
  }
  .moreDetailsHeader {
    border-radius: 10px;
    background: #cbe3d9;
    padding: 1rem;
    h6 {
      margin: 0;
      color: #1c1b1f;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .moreScroll {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;
  }
  .moreDetailsMain {
    .img {
      background-color: #fff;
      border-radius: 15px;
      padding: 15px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .content {
      h6 {
        color: #1c1b1f;
        // font-family: Poppins;
        font-family: $font2;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
      }
      p {
        font-size: 14px;
        strong {
          font-family: $font2;
        }
      }
    }
  }
  .moreDetailsFooter {
    p {
      font-size: 14px;
    }
  }
}

.membershipPage {
  font-family: $font;
  .mbHeader {
    padding: 2rem 0;
    margin-bottom: 2rem;
    img {
      max-width: 205px;
      @include lg {
        max-width: 195px;
      }
      @include md {
        max-width: 185px;
      }
      @include sm {
        max-width: 175px;
      }
    }
  }
  .mbBody {
    h4 {
      color: #000;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 1rem;
      font-family: $font2;
      @include lg {
        font-size: 26px;
      }
      @include md {
        font-size: 24px;
      }
      @include sm {
        font-size: 20px;
      }
    }
    .mbCheck {
      position: relative;
      padding-left: 112px;
      .form-check-input {
        background-color: $primary;
        border-color: $primary;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
        box-shadow: none;
      }
      .form-check-label {
        color: #1c1b1f;
        font-size: 16px;
        font-weight: 500;
        &:first-child {
          position: absolute;
          left: 0;
        }
      }
    }
    .packageBlock {
      border-radius: 20px;
      background: #e4e4e4;
      padding: 2rem;
      height: 100%;
      text-align: center;
      .pa_title {
        color: #000;
        font-size: 24px;
        font-weight: 500;
        font-family: $font2;
      }
      .pa_value {
        color: #000;
        font-size: 40px;
        font-weight: 500;
        font-family: $font2;
      }
      .pa_duration {
        color: #000;
        font-size: 14px;
        font-weight: 400;
      }
      .btn {
        border-radius: 5px;
        background: #1d1d1d;
        box-shadow: 0px 5px 15px 0px rgba(60, 60, 60, 0.4);
        min-width: 140px;
        color: #fff;
        font-size: 14px;
        min-height: 40px;
        font-weight: 500;
      }
      .pa_ul {
        text-align: start;
        margin: 1.5rem 0;
        padding-left: 15px;
        @include xl {
          padding-left: 10px;
        }
        li {
          position: relative;
          list-style-type: none;
          margin-bottom: 1rem;
          padding-left: 20px;
          color: #1d1d1d;
          font-size: 14px;
          font-weight: 400;
          &:before {
            content: "\F26A";
            font-family: "Bootstrap Icons";
            position: absolute;
            left: 0;
            top: 4px;
            display: inline-block;
            font-family: bootstrap-icons !important;
            font-style: normal;
            font-weight: 400 !important;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            vertical-align: -0.125em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        }
      }

      &.starter {
        background-image: linear-gradient(50deg, #78cd9e, #20c37c);
        .pa_title,
        .pa_value,
        .pa_duration,
        .pa_ul li {
          color: #fff;
        }
        .btn {
          border-radius: 5px;
          background: #00814a;
          box-shadow: 0px 5px 15px 0px rgba(10, 103, 61, 0.4);
        }
      }
      &.professional {
        // background-image: linear-gradient(50deg, #7fbe6a, #6b924e);
        // background: linear-gradient(
        //     0deg,
        //     rgba(0, 0, 0, 0.1) 0%,
        //     rgba(0, 0, 0, 0.1) 100%
        //   ),
        //   url(../../Assets/professional_image.png),
        //   lightgray -263px 0px / 162.305% 100% no-repeat;
        // background-repeat: no-repeat;
        // background-size: cover;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.15) 0%,
            rgba(0, 0, 0, 0.15) 100%
          ),
          radial-gradient(
            84.63% 106.42% at 28.52% 80.73%,
            #8edf72 0%,
            #7a9974 100%
          );
        background-color: #8edf72;
        .pa_title,
        .pa_value,
        .pa_duration,
        .pa_ul li {
          color: #fff;
        }
        .btn {
          background: #00814a;
          box-shadow: 0px 5px 15px 0px rgba(8, 65, 39, 0.4);
        }
      }
      &.enterprise {
        // background-image: linear-gradient(50deg, #3d8561, #26b071);
        // background: url(../../Assets/gredient_4.png),
        //   lightgray 50% / cover no-repeat;
        // background-repeat: no-repeat;
        // background-size: cover;
        // height: 100%;
        // border-radius: 20px;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.15) 0%,
            rgba(0, 0, 0, 0.15) 100%
          ),
          radial-gradient(86.8% 107.25% at 29% 83.57%, #21ba74 0%, #4b6b5c 100%);
        background-color: #21ba74;
        .pa_title,
        .pa_value,
        .pa_duration,
        .pa_ul li {
          color: #fff;
        }
        .btn {
          background: #fff;
          color: $primary;
          box-shadow: 0px 5px 15px 0px rgba(8, 65, 39, 0.4);
        }
      }

      @include xl {
        padding: 1.5rem;
      }
      @include lg {
        padding: 1rem;
      }
    }
    .card.paymentCard {
      border-radius: 20px;
      background: #eaf3ef;
      overflow: hidden;
      height: auto;
      border: 0;
      padding: 0;
      .card-header,
      .card-body,
      .card-footer {
        background-color: transparent;
        padding: 1.5rem;
      }
      .card-header {
        h6 {
          color: #000;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0.2px;
        }
        p {
          color: #313131;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 0;
        }
        .btn {
          font-size: 14px;
          color: $primary;
        }
      }
      .card-body {
        ul {
          margin: 0;
          li {
            color: #313131;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0.7rem;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .card-footer {
        color: #313131;
        font-size: 14px;
        font-weight: 500;
        .btn {
          border-radius: 10px;
          font-size: 14px;
        }
      }
    }
    .msp_left {
      h3 {
        color: #000;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 1.5rem;
      }
      .form-control {
        border-radius: 10px;
        background: #f0f0f0;
        height: 50px;
        color: #6b6b6b;
        font-size: 14px;
        font-weight: 500;
        box-shadow: none;
        &::placeholder {
          opacity: 0.5;
        }
        &:focus {
          border-color: $primary;
        }
        &.border-danger {
          border-color: var(--bs-danger) !important;
        }
        &[diabled] {
          background-color: var(--bs-secondary-bg) !important;
          opacity: 1;
        }
      }
      .form-check-label {
        color: #6b6b6b;
        font-size: 14px;
        font-weight: 500;
      }
      .form-check-input {
        &:checked {
          background-color: $primary;
          border-color: $primary;
          box-shadow: none;
        }
      }
      small {
        margin-top: -15px;
        margin-bottom: 0.5rem;
      }
    }
  }
}

.btn-main {
  background-color: $primary;
  border-color: $primary;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 0.7rem 1rem;
  border-radius: 32px;
  &:hover {
    background-color: $primary;
    border-color: $primary;
    color: #fff;
  }
  &:disabled {
    background-color: $primary;
    border-color: $primary;
    color: #fff;
  }
}
.btn-main2 {
  background-color: $secondary;
  border-color: $secondary;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 0.7rem 1rem;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &:hover {
    background-color: $secondary;
    border-color: $secondary;
    color: #fff;
  }
  &:disabled {
    background-color: $secondary;
    border-color: $secondary;
    color: #fff;
  }
}

.radius-0 {
  border-radius: 0 !important;
}
.radius-10 {
  border-radius: 10px !important;
}

.new_scroll::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.new_scroll::-webkit-scrollbar-track {
  background: #cbe3d9;
  border-radius: 5px;
}

.new_scroll::-webkit-scrollbar-thumb {
  background: $primary;
  opacity: 0.9;
  border-radius: 5px;
}
.new_scroll::-webkit-scrollbar-thumb:hover {
  background: $primary;
  opacity: 1;
}

.new_scroll2::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.new_scroll2::-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 5px;
}

.new_scroll2::-webkit-scrollbar-thumb {
  background: #aaa;
  opacity: 0.9;
  border-radius: 5px;
}
.new_scroll2::-webkit-scrollbar-thumb:hover {
  background: #999;
  opacity: 1;
}

// a, a:hover {
//     text-decoration: none;
// }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.paswwordStrength {
  width: 100px;
  position: relative;
  height: 5px;
  background-color: #ccc;
  .progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #000;
  }
}

.welcome {
  h3 {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    @include lg {
      font-size: 26px;
    }
    @include sm {
      font-size: 20px;
    }
  }
  .btn {
    font-size: 18px;
    @include lg {
      font-size: 16px;
    }
    @include sm {
      font-size: 14px;
    }
  }
}

.video-container {
  position: relative;
  background-color: var(--primary);
  border-radius: 10px;
  overflow: hidden;
  padding: 1.5rem;
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    font-size: 36px;
    width: 80px;
    height: 80px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 55px;
    filter: drop-shadow(0px 3.776px 12.587px rgba(0, 0, 0, 0.15));
    @include lg {
      width: 60px;
      height: 60px;
      font-size: 40px;
    }
    @include sm {
      width: 50px;
      height: 50px;
      font-size: 30px;
    }
    i {
      color: var(--primary);
    }
  }
}

.customMultiSearch {
  position: relative;
  padding-right: 120px;
  .btn {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    border-radius: 0 0.375rem 0.375rem 0;
    width: 120px;
    height: 100%;
  }
}

.acceptTerm {
  .form-check-label {
    color: #999;
    font-size: 14px;
  }
  .form-check-input {
    box-shadow: none;
    &:checked {
      background-color: var(--primary);
      border-color: var(--primary);
    }
  }
}

.paymentLoader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff9c;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(100px);
}

.will_hide {
  .form-check {
    border-radius: 10px;
    // background: #eaeaea;
    position: relative;
    padding: 1rem 2rem;
    z-index: 0;
    .form-check-input {
      display: none;
    }
    .form-check-label {
      cursor: pointer;
      img {
        filter: invert(1) opacity(0.75);
      }
    }
  }
}

.signUpFlow .signUpLeft .will_hide .form-check-input:checked + label {
  // font-size: 24px;
  color: #fff;
}
.signUpFlow .signUpLeft .will_hide .form-check-input:checked + label img {
  filter: none;
}
.signUpFlow .signUpLeft .will_hide .form-check-input:checked + label::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--primary);
  z-index: -1;
  border-radius: 10px;
}
.signUpFlow .signUpLeft .will_hide .form-check-label::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // background-color: #eaeaea;
  background-color: #eaeaea6e;
  backdrop-filter: blur(2px);
  z-index: -1;
  border-radius: 10px;
}

.selectedAccount {
  border-radius: 8px;
  background: rgba(32, 167, 110, 0.1);
  margin-bottom: 1.5rem;
  padding: 1rem;
  p {
    color: #999 !important;
    font-size: 14px;
    font-weight: 500;
    margin: 0 !important;
  }
  h6 {
    color: var(--primary);
    font-size: 18px;
    font-weight: 700;
  }
}

.text-primary {
  color: var(--primary) !important;
}

.surcL {
  // background-image: linear-gradient(
  //   216deg,
  //   #20a76e 0%,
  //   #007040 100%
  // ) !important;
  background-image: linear-gradient(
    216deg,
    #20a76e7d 0%,
    #007040 100%
  ) !important;
  backdrop-filter: blur(1px);
  .content {
    max-height: 75vh;
    overflow-y: auto;
    padding-right: 10px;
  }
  .accountInfoBlock {
    display: flex;
    margin-bottom: 1rem;
    .ico {
      width: 30px;
      img {
        width: 20px;
        margin-top: 6px;
      }
    }
    .cont {
      width: calc(100% - 30px);
      // padding-left: 15px;
      border-bottom: 1px solid #fff;
      h5 {
        margin: 0;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      p {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

#tsparticles canvas {
  opacity: 0.6;
}
.font-geeky {
  font-family: $font2 !important;
}
.offcanvas {
  background-color: rgb(255 255 255 / 82%);
  backdrop-filter: blur(10px);
  .accountInfoBlock {
    .ico {
      display: none;
    }
    h5 {
      font-weight: 600;
      color: $primary;
      font-size: 18px;
      margin-bottom: 0.5rem;
      font-family: $font2;
    }
    p {
      font-family: $font;
      font-size: 14px;
    }
  }
}
.offcanvas-backdrop.show {
  opacity: 0.25;
}

.switchSubduration  {
  .form-check-input{
    box-shadow: none!important;
    &:checked {
      background-color: var(--primary);
      border-color: var(--primary);
    }
  }
}

@media only screen and (max-width: 991px){
  .membershipPage .mbBody .packageBlock .pa_value {
    font-size: 30px;
  }
  .membershipPage .mbBody .packageBlock .pa_title {
    font-size: 20px;
  }
  .membershipPage .mbHeader {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
}
.NoPage {
  background: #20a76e;
  color: #fff;
  font-size: 16px;
  min-height: 100vh;
}
.NoPage h1 {
  font-size: 30vh;
}
.NoPage h2 span {
  font-size: 4rem;
  font-weight: 600;
}
.NoPage a:link,
.NoPage a:visited {
  text-decoration: none;
  color: #fff;
}
.NoPage h3 a:hover {
  text-decoration: none;
  background: #fff;
  color: #3498db;
  cursor: pointer;
}

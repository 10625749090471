@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
.card.elevateCard{
    border-radius: 12px;
    background: linear-gradient(91deg, #099359 7.24%, #0A7B4C 85.97%), #FAF8F5;
    border: 0;
    color: #fff;
    height: 100%!important;
    position: relative;
    overflow: hidden;
}
.card.elevateCard::after{
    content: "";
    width: 300px;
    height: 300px;
    /* background-color: #0cac70; */
    background: rgba(255, 255, 255, 0.40);
    mix-blend-mode: soft-light;
    position: absolute;
    top: -100px;
    right: -100px;
    border-radius: 100%;
}
.elevateCard .card-title {
    color: #fff;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
}
.elevateCard .card-text {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #fff;
}
ul.elevateUl{
    list-style: none;
    margin: 0;
    padding: 0;
}
ul.elevateUl li {
    padding-left: 40px;
    margin-bottom: 15px;
    position: relative;
}
ul.elevateUl li .ulIcon{
    position: absolute;
    left: 0;
    top: 0;
}
ul.elevateUl li .ulText{
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.elevateCard .btn {
    border-radius: 100px;
    background: #FFF!important;
    color: #097B4B;
    padding: 10px 20px;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
    position: relative;
    overflow: hidden;
    z-index: 0;
    transition: all ease 0.3s;
}
.elevateCard .btn::after{
    content: "";
    width: 100%;
    height: 100%;
    background-color: #097B4B;
    position: absolute;
    top: -100%;
    left: 0;
    z-index: -1;
    transition: all ease 0.3s;
}
.elevateCard .btn:hover{
    color: #fff;
}
.elevateCard .btn:hover::after{
    top: 0;
}
.onlineSellsheading h2 {
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    font-size: 39px;
}

.subheading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #818181;
}

.imagediv {
    position: relative;
}

.mUserSubHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #414141;
}

.firstItem {
    position: absolute;
    z-index: 9;
    top: -28px;
    left: 222px;
    background-color: #ffff;
    padding: 14px 36px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 5px 5px 0px 5px;
    -webkit-box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
}
.firstItem h5{
    font-weight: 600;
}

.SecondItem {
    position: absolute;
    z-index: 9;
    top: -71px;
    right: -53px;
    background-color: #ffff;
    padding: 14px 36px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 5px 5px 5px 0px;
    -webkit-box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
-moz-box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
}

.SecondItem h5{
    font-weight: 600;
}


.thirdItem {
    position: absolute;
    z-index: 9;
    bottom: 222px;
    left: 224px;
    background-color: #ffff;
    padding: 14px 36px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 5px 0px 5px 5px;
    -webkit-box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
}
.thirdItem h5{
    font-weight: 600;
}


.fourthItem {
    position: absolute;
    z-index: 9;
    top: 96px;
    right: -53px;
    background-color: #ffff;
    padding: 14px 36px;
    border-radius: 5px 5px 5px 0px;
    font-family: 'Montserrat', sans-serif;
    -webkit-box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 25px -8px rgba(0,0,0,0.75);
}

.fourthItem h5{
    font-weight: 600;
}

.imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.imageContainer img {
    width: 100%;
}
.carousalitem1{
    z-index: 9;
    top: -28px;
    left: 222px;
    padding: 24px 36px;
    background-color: transparent !important;
}
.carousalitem2{
    z-index: 9;
    top: -28px;
    left: 222px;
    padding: 24px 36px;
    background-color: transparent !important;
}
.carousalitem3{
    z-index: 9;
    top: -28px;
    left: 222px;
    padding: 24px 36px;
    background-color: transparent !important;
}
.carousalitem4{
    z-index: 9;
    top: -28px;
    left: 222px;
    padding: 24px 36px;
}
.carousel .slide {
    margin: 0;
    min-width: 100%;
    position: relative;
    text-align: center;
    color: #ffff;
    background: #198754;
}
.carousel-root {
    outline: none;
    margin-top: 52px;
}
.carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden;
    border-radius: 6px;
}



/* strat responsive section */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .onlineSellsheading h2 {
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        font-size: 32px;
    }
    .firstItem {
        display: none;
    }

    .thirdItem {
       display: none;
    }
    .SecondItem {
        display: none;
    }
    .fourthItem {
        display: none;
    }
    .sellerisubheading p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.02em;
        color: #818181;
    }
}
@media screen and (min-width: 300px) and (max-width: 767px) {
    .firstItem {
        display: none;
    }
    .sellerisubheading p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.02em;
        color: #818181;
    }
    .thirdItem {
       display: none;
    }
    .SecondItem {
        display: none;
    }
    .fourthItem {
        display: none;
    }
    .onlineSellsheading h2 {
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        font-size: 31px;
    }
    .subheading p br{
        display: none;
        text-align: center;
    }
}

/* end responsive section */

/* @media screen and (min-width: 992px) and (max-width: 1199px) {
    .firstItem {
        position: absolute;
        z-index: 9;
        top: -28px;
        left: 116px;
        background-color: #2F2F2F;
        padding: 14px;
        border-radius: 5px 5px 0px 5px;
    }

    .thirdItem {
        position: absolute;
        z-index: 9;
        bottom: 129px;
        left: 116px;
        background-color: #2F2F2F;
        padding: 14px;
        border-radius: 5px 0px 5px 5px;
    }
    .SecondItem {
        position: absolute;
        z-index: 9;
        top: -71px;
        right: -13px;
        background-color: #2F2F2F;
        padding: 14px;
        border-radius: 5px 5px 5px 0px;
    }
    .fourthItem {
        position: absolute;
        z-index: 9;
        top: 120px;
        right: -18px;
        background-color: #2F2F2F;
        padding: 14px;
        border-radius: 5px 5px 5px 0px;
    }
} */

/* @media screen and (min-width: 768px) and (max-width: 991px) {
    .firstItem {
        position: absolute;
        z-index: 9;
        top: -28px;
        left: 24px;
        background-color: #2F2F2F;
        padding: 14px;
        border-radius: 5px 5px 0px 5px;
    }

   

    .thirdItem {
        position: absolute;
        z-index: 9;
        bottom: 48px;
        left: 24px;
        background-color: #2F2F2F;
        padding: 14px;
        border-radius: 5px 0px 5px 5px;
    }

    .fourthItem {
        position: absolute;
        z-index: 9;
        top: 120px;
        right: -18px;
        background-color: #2F2F2F;
        padding: 14px;
        border-radius: 5px 5px 5px 0px;
    }
    .heading h2{
        font-weight: 200;
        font-family: 'Prata', serif;
        margin-top: 26px;
    }
    .subheaderSection{
        width: 100%;
    }
    .pricingList{
        width: 100% !important;
    }
    .buttons button{
        font-size: 12px;
    }
    .subheaderSection p {
        font-size: 10px;
        font-weight: 600;
        word-spacing: 0px;
    }
    .secondContainer .subheaderSection p {
        font-size: 10px;
        font-weight: 500;
        word-spacing: 0px;
    }
} */
/* @media screen and (min-width: 300px) and (max-width: 767px){
    .heading h2 {
        font-size: 21px;
    }
    .SecondItem {
        margin-top: 10px;
        position: initial;
        z-index: 9;
        top: 7px;
        background-color: #2F2F2F;
        padding: 14px;
        border-radius: 5px 5px 5px 0px;
    }
    .firstItem{
        position: initial;
        z-index: 9;
        margin-top: 10px;
        background-color: #2F2F2F;
        padding: 14px;
        border-radius: 5px 5px 5px 0px; 
    }
    .thirdItem{
        position: initial;
        margin-top: 10px;
        z-index: 9;
        background-color: #2F2F2F;
        padding: 14px;
        border-radius: 5px 5px 5px 0px; 
    }
    .fourthItem{
        position: initial;
        z-index: 9;
        margin-top: 10px;
        background-color: #2F2F2F;
        padding: 14px;
        border-radius: 5px 5px 5px 0px; 
    }
    .subheading p{
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
    }
    .subheading p br{
       display: none;
    }
} */

/* @media screen and (max-width: 300px){
    
    .SecondItem {
        margin-top: 10px;
        position: initial;
        z-index: 9;
        top: 7px;
     
        background-color: #2F2F2F;
        padding: 14px;
        border-radius: 5px 5px 5px 0px;
    }
    .firstItem{
        position: initial;
        z-index: 9;
        margin-top: 10px;
        background-color: #2F2F2F;
        padding: 14px;
        border-radius: 5px 5px 5px 0px; 
    }
    .thirdItem{
        position: initial;
        margin-top: 10px;
        z-index: 9;
        background-color: #2F2F2F;
        padding: 14px;
        border-radius: 5px 5px 5px 0px; 
    }
    .fourthItem{
        position: initial;
        z-index: 9;
        margin-top: 10px;
        background-color: #2F2F2F;
        padding: 14px;
        border-radius: 5px 5px 5px 0px; 
    }
} */
.banner{
    display: flex;
    align-items: center;
    justify-content: center;
}
.topbody_Heading {
    font-family: 'Montserrat', sans-serif;
    font-size: 51px;
    letter-spacing: 0px;
    line-height: 1.15;
    font-weight: 700;
}
.top_body_subheading {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #818181;
    letter-spacing: 0.02em;
}
.getmemberbtncontainer a, .getmemberbtncontainer button{
    border-radius: 30px;
    margin: 27px 0px 20px 0px;
    padding: 12px 40px;
    color: #000000de;
    font-weight: 700;
}
.getmemberbtncontainer Button:hover{
    box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 3px 4px 17px 0px rgba(0,0,0,0.75);
}
.getmember {
    border-radius: 20px;
    margin: 20px 0px 20px 0px;
    padding: 6px 25px; 
}
/* .backgroundContainer{
    width: 100%;
    background-image: url('../../Assets/Bannerbgimage.png');
    background-position: center;
    position: relative;
    z-index: 9;
} */

/* Start responsive  */

@media screen and (min-width: 300px) and (max-width: 575px){
    .top_body_leftside .topbody_Heading {
        font-size: 22px;
        text-align: center;
        line-height: 33px;
    }
    .top_body_leftside .topbody_Heading br{
        display: none;
    }
    .top_body_subheading {
        font-size: 17px;
        margin-top: 16px;
        font-weight: 300;
        line-height: 32px;
        text-align: center;
    }
    .getmemberbtncontainer{
        display: flex;
        justify-content: center;
    }
    .top_body_subheading br{
        display: none;
    }
    .ourfeaturess {
        padding-left: 0rem !important;
        margin-left: 1.5rem;
        text-align: center;
    } 
    .ourfeaturess BR{
        display: none;
    }


}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .top_body_leftside .topbody_Heading {
        font-size: 32px;
        text-align: center;
        line-height: 33px;
    }
    .top_body_leftside .topbody_Heading br{
        display: none;
    }
    .top_body_subheading {
        font-size: 15px;
        margin-top: 16px;
        font-weight: 300;
        line-height: 32px;
        text-align: center;
    }
    .getmemberbtncontainer{
        display: flex;
        justify-content: center;
    }
}
@media screen and (min-width: 768px) and (max-width: 1199px){
    .top_body_leftside .topbody_Heading{
        font-size: 22px;
    }
    .top_body_rightside{
        margin-top: 50px;
    }
    .top_body_subheading {
        font-size: 13px;
        font-weight: 300;
        line-height: 20px;
        margin-top: 9px;
        font-weight: 500;
    }
    .getmemberbtncontainer a, .getmemberbtncontainer button {
        border-radius: 30px;
        color: #000000de;
        font-weight: 500;
        margin: 5px 0 3px;
        padding: 8px 15px;
        color: #fff;
    }
}



/* end responsive */

